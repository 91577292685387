import {MapperUtility} from "@/data/mapper/index";
// import {PermissionMapper} from "@/data/mapper/permission.mapper";

export class RoleMapper {
  static mapToRoles(response) {
    MapperUtility.dataLogger('*** start mapping role *** ')
    MapperUtility.dataLogger(response)
    MapperUtility.dataLogger('*** start mapping *** ')
    const responseList = response.payload.data ? response.payload.data : []
    const responseLinks = response.payload.links ? response.payload.links : []
    MapperUtility.dataLogger('mapped responselist => ', responseList)
    const role = {
      list: [],
      link: [],
      first: response.payload ? response.payload.first_page_url : null,
      last: response.payload ? response.payload.last_page_url : null,
      prev: response.payload ? response.payload.prev_page_url : null,
      next: response.payload ? response.payload.next_page_url : null,
    }
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const obj = this.mapToRole(data)
        role.list.push(obj)
      })
    }
    if (responseLinks.length > 0) {
      responseLinks.forEach(data => {
        const link = MapperUtility.mapToLink(data)
        role.link.push(link)
      })
    }
    return role
  }
  static mapToRole(response) {
    MapperUtility.dataLogger('mapping resp => ', response)
    const role = {
      id: MapperUtility.checkData(response.id),
      name: MapperUtility.checkData(response.name),
      displayName: MapperUtility.checkData(response.display_name),
      description: MapperUtility.checkData(response.description),
      parentRole: MapperUtility.checkData(response.parent_role),
      permissions: [],
    }
    if (response.permissions) {
      if (response.permissions.length > 0) {
        response.permissions.forEach(data => {
          // const obj = PermissionMapper.mapToPermission(data)
          // role.permissions.push(obj)
          role.permissions.push(data.id)
        })
      }
    }
    return role
  }
  static mapToRoleJson(data) {
    MapperUtility.dataLogger('mapping json => ', data)
    const json = {
      'name': MapperUtility.checkData(data.name),
      // 'display_name': MapperUtility.checkData(data.displayName),
      'description': MapperUtility.checkData(data.description),
      'permissions': [...data.permissions],
    }
    return json
  }
  static mapToUpdateRoleJson(data) {
    MapperUtility.dataLogger('start mapping json => ', data)
    const json = {
      'permissions': [...data.permissions],
    }
    return json
  }
}
