import {orderInstance as api} from "@/data/services/api";
import {OrderMapper} from "@/data/mapper/order.mapper";

export class OrderService {
  getOrders(options) {
    let url = ''
    console.log('listOption',options)
    if (options.perPage) url = `${url}per_page=${options.perPage}`
    if(options.start_date)url = `${url}&start_date=${options.start_date}`
    if(options.end_date)url = `${url}&end_date=${options.end_date}`
    if(options.filterStatus)url = `${url}&filter[status]=${options.filterStatus}`
    if (options.search) url = `${url}&filter[search]=${options.search}`
    if(options.pageUrl){
      return api
      .get(`${options.pageUrl}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return OrderMapper.mapToOrders(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      });
    }else{
      return api
        .get(`/orders${url ? '?' + url : ''}`)
        .then(response => {
          if (response.status >= 200 && response.status < 300) {
            return OrderMapper.mapToOrders(response.data);
          } else {
            throw 'Error: Wrong Status';
          }
        });
    }
  }
  getOrderSummary() {
    return api
      .get('/order-summary')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return OrderMapper.mapToOrderSummary(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      });
  }
  getOrderStatuses() {
    return api
      .get('/order-statuses')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return OrderMapper.mapToOrderStatuses(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      });
  }
  getOrder(id) {
    console.log('id => ', id)
    return api
      .get(`/orders/${id}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return OrderMapper.mapToOrder(response.data.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        console.log('err at api', e)
        throw e
      });
  }
  changeOrderStatus (id, status) {
    const request = {
      'status': status
    }
    return api
      .put(`/orders/${id}/update-status`, request)
      .then(response => {
        if (response) {
          if (response.status >= 200 && response.status < 300) {
            return true;
          } else {
            throw 'Error: Wrong Status';
          }
        }
      })
  }
  changePaymentStatus (id, status) {
    const request = {
      'status': status
    }
    return api
      .put(`/orders/${id}/update-payment-status`, request)
      .then(response => {
        if (response) {
          if (response.status >= 200 && response.status < 300) {
            return true;
          } else {
            throw 'Error: Wrong Status';
          }
        }
      })
  }
  getCartItems() {
    return api
      .get('/cart')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return OrderMapper.mapToCartItems(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      });
  }
  removeCartItem(rowId) {
    return api
      .post(`/cart/${rowId}/remove-item`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      });
  }
  removeAllCartItems() {
    return api
      .delete(`/cart`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      });
  }

  //recent order for dashboard
  getRecentOrder(){
    return api.get(`/stats`).then(response =>{
      if (response.status >= 200 && response.status < 300){
        return OrderMapper.mapToRecentOrders(response.data);
      }else{
        throw "Error: Wront Status";
      }
    })
  }
}
