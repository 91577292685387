import {MapperUtility} from "@/data/mapper/index";
import nav from '@/_nav.js'

export class AuthMapper {
  static mapToLoginRequestJson (data) {
    const request = {
      'mobile': data.login,
      'password': data.password,
      'mode': 'headquarter',
    }
    return request;
  }
  static mapToToken (response) {
    MapperUtility.dataLogger('mapping token ', response)
    const token = {
      id: response.user.id,
      mobile: response.user.mobile,
      emailVerified: response.user.email_verified,
      firstName: response.user.first_name,
      lastName: response.user.last_name,
      status: response.user.status,
      accessToken: response.payload.access_token,
      tokenType: response.payload.token_type,
      expiresIn: response.payload.expires_in,
      refreshToken: response.payload.refresh_token,
    }
    return token;
  }
  static mapToAuthDetail (response) {
    const data = {
      permissionList: [],
      permissionIds: [],
      navList: [],
      roles: []
    }
    // permission
    const permissions = response.payload.permissions
    permissions.forEach(permission => {
      data.permissionList.push(permission.name)
      data.permissionIds.push(permission.id)
    })
    // roles
    const roles = response.payload.roles
    roles.forEach(role => {
      data.roles.push(role.name)
    })
    if (data.roles.includes('super-admin')) {
      MapperUtility.dataLogger('is super admin')
      data.navList = [...nav]
    } else {
      MapperUtility.dataLogger('is not super admin')
      data.navList.push({
        component: 'CNavItem',
        name: 'Dashboard',
        key: 'dashboard',
        to: '/dashboard'
      })
      nav.forEach(navData => {
        if (navData.items) {
          const navDataObj = {
            component: navData.component,
            name: navData.name,
            items: []
          }
          navData.items.forEach(innerNav => {
            if (innerNav.items) {
              const innerNavObj = {
                component: innerNav.component,
                name: innerNav.name,
                to: innerNav.to,
                items: []
              }
              innerNav.items.forEach(lastNav => {
                const result = this.checkNavItem(lastNav, data.permissionList)
                if (result) {
                  innerNavObj.items.push(lastNav)
                }
              })
              if (innerNavObj.items.length > 0) {
                navDataObj.items.push(innerNavObj)
              }
            } else {
              const result = this.checkNavItem(innerNav, data.permissionList)
              if (result) {
                navDataObj.items.push(innerNav)
              }
            }
          })
          if (navDataObj.items.length > 0) {
            data.navList.push(navDataObj)
          }
        } else {
          const result = this.checkNavItem(navData, data.permissionList)
          if (result) {
            data.navList.push(navData)
          }
        }
      })
    }
    MapperUtility.dataLogger('mapping auth detail', data)
    return data
  }
  static checkNavItem (nav, permissionList) {
    MapperUtility.dataLogger('check nav item start')
    MapperUtility.dataLogger('nav = ', nav)
    MapperUtility.dataLogger('permission list = ', permissionList)
    if (nav.key && permissionList.includes(nav.key)) {
      return true
    } else {
      return false
    }
  }
  static mapToRegisterRequestJson (data) {
    const request = {
      'fullname': data.fullName,
      'login': data.login,
      'password': data.password,
    }
    return request;
  }
}
