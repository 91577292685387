import axios from "axios";
import {getToken, getTokenType} from "@/data/preserved-storage";

// console.log('token at api instance => ')
// console.log('token at api instance => ', getToken())

const authInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_AUTH_URL}`,
  headers: {
    "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    "Authorization": `${getTokenType()} ${getToken()}`
  },
});
const catalogInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_CATALOGUE_URL}`,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `${getTokenType()} ${getToken()}`
  },
});
const orderInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_ORDER_URL}`,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `${getTokenType()} ${getToken()}`
  },
});
const generalInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_GENERAL_URL}`,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `${getTokenType()} ${getToken()}`
  },
});
export {authInstance, catalogInstance, orderInstance, generalInstance};
