
import { OrderService } from '@/data/services/order.service'
// import { MapperUtility } from '@/data/mapper'

export const order = {
  namespaced: true,
  state: {
    perpage:null,
    search: '',
    filterStatus: null,
    start_date: '',
    end_date:'',
    ordersList: [],
    pageUrl:'',
    recentOrderList:[]
  },
  actions: {
    getRecentOrders({commit}) {
      const orderService = new OrderService()
      return orderService.getRecentOrder().then(
        response => {
          commit('setRecentOrderListState', response);
          // commit('setSubCategoryState', response.subCategories);
          return Promise.resolve(true);
        },
        error => {
          // MapperUtility.dataLogger('error at vuex', error)
          return Promise.reject(error);
        }
      )
    },
    getOrders({commit,state}) {
      const listOptions = {
        perPage: state.perpage,
        search: state.search,
        filterStatus: state.filterStatus,
        start_date: state.start_date,
        end_date: state.end_date,
        pageUrl:state.pageUrl
      }
      console.log('lisrascsdc:',listOptions)
        const orderService = new OrderService()
        return orderService.getOrders(listOptions).then(
          response => {
            commit('setOrderListState', response);
            // commit('setSubCategoryState', response.subCategories);
            return Promise.resolve(true);
          },
          error => {
            // MapperUtility.dataLogger('error at vuex', error)
            return Promise.reject(error);
          }
        )
      },
  },
  mutations: {
    setOrderListState(state, list) {
      // MapperUtility.dataLogger('setCategoryState mutate', list);
      state.ordersList = list;
    },
    setRecentOrderListState(state, list) {
        // MapperUtility.dataLogger('setCategoryState mutate', list);
        state.recentOrderList = list;
      },
  },
  getters: {
    getOrderListState (state) {
      return state.ordersList.list
    },
    getRecentOrderListState (state) {
        return state.recentOrderList.list
      },
  }
}
