import {MapperUtility} from "@/data/mapper/index";
import {RoleMapper} from "@/data/mapper/role.mapper";

export class UserMapper {
  static mapToUsers(response) {
    MapperUtility.dataLogger('*** start mapping role *** ')
    MapperUtility.dataLogger(response)
    MapperUtility.dataLogger('*** start mapping *** ')
    const responseList = response.payload.data ? response.payload.data : []
    const responseLinks = response.payload.links ? response.payload.links : []
    MapperUtility.dataLogger('mapped responselist => ', responseList)
    // const user = {
    //   list: [],
    //   link: [],
    //   prev: response.prev_page_url,
    //   next: response.next_page_url,
    // }
    const user = {
      list: [],
      link: [],
      first: response.payload.first_page_url ? response.payload.first_page_url : null,
      last: response.payload.last_page_url ? response.payload.last_page_url : null,
      prev: response.payload.prev_page_url ? response.payload.prev_page_url : null,
      next: response.payload.next_page_url ? response.payload.next_page_url : null,
    }
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const obj = this.mapToUser(data)
        user.list.push(obj)
      })
    }
    if (responseLinks.length > 0) {
      responseLinks.forEach(data => {
        const link = MapperUtility.mapToLink(data)
        user.link.push(link)
      })
    }
    return user
  }
  static mapToUser(response) {
    MapperUtility.dataLogger('mapping product resp => ', response)
    const user = {
      id: MapperUtility.checkData(response.id),
      firstName: MapperUtility.checkData(response.first_name),
      lastName: MapperUtility.checkData(response.last_name),
      fullName: `${response.first_name ? response.first_name : ''} ${response.last_name ? response.last_name : ''}`,
      email: MapperUtility.checkData(response.email),
      gender: MapperUtility.checkData(response.gender),
      mobile: MapperUtility.checkData(response.mobile),
      avatar: MapperUtility.checkData(response.avatar),
      created_at: MapperUtility.checkData(response.created_at),
      updatedAt: MapperUtility.checkData(response.updated_at),
      ipAddress: MapperUtility.checkData(response.ip_address),
      countryId: MapperUtility.checkData(response.country_id),
      countryCode: MapperUtility.checkData(response.country_code),
      status: response.status === "0" ? false : true,
      emailVerified: response.email_verified === "0" ? false : true,
      isSubscribed: response.is_subscribed === "0" ? false : true,
      role: response.roles ? RoleMapper.mapToRole(response) : null
    }
    return user
  }
}
