import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import ElementPlus from 'element-plus'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel3';


import './styles/style.scss'
import {checkMyPermission} from "@/data/services/permissionHandler";
import CKEditor from '@mayasabha/ckeditor4-vue3';


import helpers from "./helper";



// Log .env values during testing
console.table(process.env);

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(ElementPlus)
app.use(VueLoading)
app.use( CKEditor )
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component("downloadExcel", JsonExcel)
app.config.globalProperties.$checkMyPermission = checkMyPermission
app.config.globalProperties.$helpers = helpers

app.mount('#app')
