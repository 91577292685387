<template>
    <div> </div>
  </template>
  
  <script>

  
  export default {
    name: 'AutoCallOrderApi',
  
    data() {
      return {
        events: ['load'],
        callOrderTimer: null,
      }
    },
  
    mounted() {
      this.events.forEach(function (event) {
        window.addEventListener(event, this.resetTimmer)
      }, this);
  
      this.setTimers();
    },
  
    unmounted(){
      this.events.forEach(function (event) {
        window.removeEventListener(event, this.resetTimmer)
      }, this);
  
      this.resetTimmer();
    },
    
    methods: {
      setTimers() {
        this.callOrderTimer = setTimeout(this.logoutUser, 5 * 60 * 1000)
      },
     async logoutUser() {
        await this.$store.dispatch('order/getRecentOrders').then(() => {})
        await this.$store.dispatch('order/getOrders').then(() => {})
        this.setTimers();
      },
      resetTimmer() {
        clearTimeout(this.callOrderTimer);
        
        this.setTimers();
      },
    },
  }
  </script>
  
  <style>
  </style>