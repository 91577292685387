import AuthService from "@/data/services/auth.service";
import {removeToken, removeTokenType, setToken, setTokenType} from "@/data/preserved-storage";

export const auth = {
  namespaced: true,
  state: {
    loggedIn: false,
    tokenData: null,
    roles: [],
    permissions: [],
    permissionIds: [],
    nav: [],
  },
  actions: {
    login({commit}, user) {
      return AuthService.login(user).then(
        response => {
          commit('loginSuccess', response);
          return Promise.resolve(response);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      )
    },
    getAuthDetail({commit}, token) {
      return AuthService.getAuthDetail(token).then(
        response => {
          commit('setRoles', response.roles);
          commit('setPermission', response.permissionList);
          commit('setPermissionIds', response.permissionIds);
          commit('setNav', response.navList);
          return Promise.resolve(response);
        },
        error => {
          AuthService.logout();
          removeTokenType()
          removeToken()
          commit('logout');
          return Promise.reject(error);
        }
      )
    },
    logout({ commit }) {
      AuthService.logout();
      removeTokenType()
      removeToken()
      commit('logout');
    },
  },
  mutations: {
    loginSuccess(state, token) {
      setToken(token.accessToken)
      setTokenType(token.tokenType)
      state.loggedIn = true;
      state.tokenData = token;
    },
    loginFailure(state) {
      state.loggedIn = false;
      state.tokenData = null;
    },
    setRoles(state, payload) {
      state.roles = payload;
    },
    setPermission(state, payload) {
      state.permissions = payload;
    },
    setPermissionIds(state, payload) {
      state.permissionIds = payload;
    },
    setNav(state, payload) {
      state.nav = payload;
    },
    logout(state) {
      state.loggedIn = false;
      state.tokenData = null;
      state.roles = [];
      state.permissions = [];
      state.permissionIds = [];
      state.nav = [];
    },
  },
  getters: {
    getTokenState (state) {
      return state.tokenData
    },
    getNavState (state) {
      return state.nav
    }
  }
}
