import {catalogInstance as api} from "@/data/services/api";
import {CatalogMapper} from "@/data/mapper/catalog.mapper";
import TokenService from "@/data/services/token.service"
import {MapperUtility} from "@/data/mapper";

export class CatalogService {
  getCategoryList() {
    return api
      .get('/categories')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return CatalogMapper.mapToCategoryList(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        MapperUtility.dataLogger(e)
        throw e
      });
  }
  getCategories() {
    return api
      .get('/categories')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return CatalogMapper.mapToCategories(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        MapperUtility.dataLogger(e)
        throw e
      });
  }
  getCategory(id) {
    return api
      .get(`/categories/${id}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return CatalogMapper.mapToCategory(response.data.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  addCategory(data) {
    api.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getLocalAccessToken()}`
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
    const request = CatalogMapper.mapToCategoryJson(data)
    return api
      .post('/categories', request)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  updateCategory(id, data) {
    // api.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getLocalAccessToken()}`
    api.defaults.headers.post['Content-Type'] = 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
    const request = CatalogMapper.mapToUpdateCategoryJson(data)
    return api
      .post(`/categories/${id}`, request)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  deleteCategory(id) {
    return api
      .delete(`/categories/${id}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  getProductAttributes() {
    return api
      .get('/product-attributes')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return CatalogMapper.mapToProductAttributes(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  getProductAttribute(id) {
    return api
      .get(`/product-attributes/${id}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return CatalogMapper.mapToProductAttribute(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  addProductAttribute(data) {
    const request = CatalogMapper.mapToProductAttributeJson(data)
    return api
      .post('/product-attributes', request)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  updateProductAttribute(data) {
    const request = CatalogMapper.mapToProductAttributeJson(data)
    return api
      .post('/product-attributes', request)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  getProductUnitList() {
    return api
      .get('/units')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return CatalogMapper.mapToUnitList(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        console.log('unit list err', e)
        throw e
      });
  }
  getProductUnits() {
    return api
      .get('/units')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return CatalogMapper.mapToProductUnits(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        console.log('unit list err', e)
        throw e
      });
  }
  getProductUnit(id) {
    return api
      .get(`/units/${id}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          console.log('api response ', response)
          return CatalogMapper.mapToProductUnit(response.data.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  addProductUnit(data) {
    const request = CatalogMapper.mapToProductUnitJson(data)
    return api
      .post('/units', request)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  updateProductUnit(id, data) {
    const request = CatalogMapper.mapToProductUnitJson(data)
    return api
      .put(`/units/${id}`, request)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  deleteProductUnit(id) {
    return api
      .delete(`/units/${id}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  getReviews() {
    return api
      .get('/ratings')
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return CatalogMapper.mapToReviews(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        console.log('reviews list err', e)
        throw e
      });
  }
  getReview(id) {
    return api
      .get(`/ratings/${id}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          console.log('api response ', response)
          return CatalogMapper.mapToReview(response.data.data);
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  deleteReview(id) {
    return api
      .delete(`/ratings/${id}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
  changeReviewStatus(id, url) {
    return api
      .post(`/ratings/${id}/${url}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return true;
        } else {
          throw 'Error: Wrong Status';
        }
      })
      .catch(e => {
        throw e
      });
  }
}
