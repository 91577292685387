export class MapperUtility {
  static checkData(data) {
    return (data !== 'undefined') ? data : ''
  }
  static metaData(data) {
    const meta = {
      title: data.meta_title ? data.meta_title : '',
      keyword: data.meta_keyword ? data.meta_keyword : '',
      description: data.meta_description ? data.meta_description : '',
      keyphrase: data.meta_keyphrase ? data.meta_keyphrase : '',
    }
    return meta
  }
  static dataLogger(msg, value = '') {
    if (process.env.VUE_APP_MODE === 'dev') {
      console.log(msg, value)
    }
  }
  static mapToLink(response) {
    MapperUtility.dataLogger('mapping link resp => ', response)
    const link = {
      url: MapperUtility.checkData(response.url),
      label: MapperUtility.checkData(response.label),
      active: MapperUtility.checkData(response.active),
    }
    return link
  }
  static getIdFromList (value, list) {
    this.dataLogger('list => ', list)
    return list.filter(data => {
      if (data.name.toLowerCase() === value.toLowerCase()) {
        this.dataLogger('filttered data => ', data)
        return data.id
      }
    })
  }
  static getFormatedDate (value) {
    const date = new Date(value)
    const dateText = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    this.dataLogger('date text = ', dateText)
    return dateText
  }
}
