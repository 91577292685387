import {MapperUtility} from "@/data/mapper/index";
import {CatalogMapper} from "@/data/mapper/catalog.mapper";

export class ProductMapper {
  static mapToProducts(response) {
    // MapperUtility.dataLogger('map to products => ', response)
    // MapperUtility.dataLogger('map to products => ', response.data)
    const responseList = response.data ? response.data : []
    // MapperUtility.dataLogger('map to products responselist => ', responseList)
    const products = {
      list: [],
      first: response.links ? response.links.first : null,
      last: response.links ? response.links.last : null,
      prev: response.links ? response.links.prev : null,
      next: response.links ? response.links.next : null,
    }
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const product = this.mapToProduct(data)
        products.list.push(product)
      })
    }
    return products
  }
  static mapToProduct(response) {
    // MapperUtility.dataLogger('mapping product resp => ', response)
    const product = {
      id: MapperUtility.checkData(response.id),
      title: MapperUtility.checkData(response.title),
      slug: MapperUtility.checkData(response.slug),
      minOrderQty: MapperUtility.checkData(response.min_order_qty),
      description: MapperUtility.checkData(response.description),
      shortDescription: MapperUtility.checkData(response.short_description),
      active: MapperUtility.checkData(response.active),
      collection: MapperUtility.checkData(response.collection),
      bestSelling: MapperUtility.checkData(response.best_selling),
      avgRating: MapperUtility.checkData(response.avg_rating),
      image: MapperUtility.checkData(response.image_url),
      price: MapperUtility.checkData(parseFloat(response.price)),
      // quantity: MapperUtility.checkData(response.quantity),
      category: response.category ? CatalogMapper.mapToCategory(response.category) : null,
      categoryId: response.category ? response.category.id : null,
      unitId: MapperUtility.checkData(response.unit),
      salePrice: response.sale_price ? MapperUtility.checkData(parseFloat(response.sale_price)) : null,
      // salePriceFrom: MapperUtility.checkData(response.sale_price_from),
      // salePriceTo: MapperUtility.checkData(response.sale_price_to),
      metaTitle: MapperUtility.checkData(response.meta_title),
      metaDescription: MapperUtility.checkData(response.meta_description),
      metaKeyPhrase: MapperUtility.checkData(response.meta_keyphrase),
      priceVariations: []
    }
    if (response.price_variations) {
      if (response.price_variations.length > 0) {
        response.price_variations.forEach(data => {
          const price = this.mapToProductPrice(data)
          if (!data.default) {
            product.priceVariations.push(price)
          }
        })
      }
    }
    return product
  }
  static mapToProductPrice (data) {
    const price = {
      id: MapperUtility.checkData(data.id),
      productId: MapperUtility.checkData(data.product_id),
      default: MapperUtility.checkData(data.default),
      minOrderQty: MapperUtility.checkData(data.min_order_qty),
      // quantity: MapperUtility.checkData(parseInt(data.quantity)),
      unitId: MapperUtility.checkData(parseInt(data.unit_id)),
      unit: MapperUtility.checkData(data.unit),
      price: data.price ? parseFloat(data.price) : null,
      salePrice: data.sale_price ? parseFloat(data.sale_price) : null,
      // salePriceFrom: MapperUtility.checkData(data.sale_price_from),
      // salePriceTo: MapperUtility.checkData(data.sale_price_to),
    }
    return price
  }
  static mapToProductImages(response) {
    const responseList = response.data ? response.data : []
    const images = []
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const image = this.mapToProductImage(data)
        images.push(image)
      })
    }
    return images
  }
  static mapToProductImage (data) {
    const image = {
      id: MapperUtility.checkData(data.id),
      productId: MapperUtility.checkData(data.product_id),
      smallImageUrl: MapperUtility.checkData(data.small_image_url),
      mediumImageUrl: MapperUtility.checkData(data.medium_image_url),
      imageUrl: MapperUtility.checkData(data.image_url),
      isFeatured: MapperUtility.checkData(data.is_featured),
    }
    return image
  }
  static mapToProductJson(data) {
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('category_id', data.subCategoryId ? data.subCategoryId : data.categoryId)
    formData.append('description', data.description)
    formData.append('min_order_qty', data.minOrderQty)
    // formData.append('quantity', data.quantity)
    formData.append('unit_id', data.unitId)
    formData.append('price', data.price)
    if (data.salePrice) formData.append('sale_price', data.salePrice)
    // if (data.salePrice) formData.append('sale_price_from', data.salePriceFrom)
    // if (data.salePrice) formData.append('sale_price_to', data.salePriceTo)
    formData.append('image', data.image)
    formData.append('active', data.active ? 1 : 0)
    formData.append('collection', data.collection ? 1 : 0)
    formData.append('best_selling', data.bestSelling ? 1 : 0)
    return formData
  }
  static mapToUpdateProductJson(data) {
    // const request = {
    //   'title': data.title,
    //   'slug': data.slug,
    //   'category_id': data.categoryId,
    //   'quantity': data.quantity,
    //   'min_order_qty': data.minOrderQty,
    //   'description': data.description,
    //   'unit_id': data.unitId,
    //   'price': data.price,
    //   'sale_price': data.salePrice,
    //   'sale_price_from': data.salePriceFrom,
    //   'sale_price_to': data.salePriceTo,
    //   'active': data.active ? '1' : '0',
    //   'in_stock': data.inStock ? '1' : '0',
    // }
    const formData = new FormData()
    formData.append('title', data.title)
    formData.append('slug', data.slug)
    formData.append('category_id', data.subCategoryId ? data.subCategoryId : data.categoryId)
    formData.append('description', data.description)
    formData.append('min_order_qty', data.minOrderQty)
    // formData.append('quantity', data.quantity)
    formData.append('unit_id', data.unitId)
    formData.append('price', data.price)
    if (data.salePrice) formData.append('sale_price', data.salePrice)
    // if (data.salePrice) formData.append('sale_price_from', data.salePriceFrom)
    // if (data.salePrice) formData.append('sale_price_to', data.salePriceTo)
    formData.append('image', data.image)
    formData.append('active', data.active ? 1 : 0)
    formData.append('collection', data.collection ? 1 : 0)
    formData.append('best_selling', data.bestSelling ? 1 : 0)
    formData.append('_method', 'put')
    return formData
  }
  static mapToProductPriceJson(id, data) {
    MapperUtility.dataLogger('mapper start ', id)
    MapperUtility.dataLogger('mapper start ', data)
    const request = {
      'product_id': id,
      'variations': [],
    }
    if (data.length > 0) {
      data.forEach(_ => {
        const variation = {
          'price': _.price,
          // 'quantity': _.quantity,
          'unit_id': _.unitId,
        }
        if (_.id) variation['id'] = _.id
        if (_.salePrice) {
          variation['sale_price'] = _.salePrice
          // variation['sale_price_from'] = _.salePriceFrom
          // variation['sale_price_to'] = _.salePriceTo
        }
        if (_.minOrderQty) {
          variation['min_order_qty'] = _.minOrderQty
        }
        request.variations.push(variation)
      })
    }
    MapperUtility.dataLogger('mapper end', request)
    return request
  }

  

}
