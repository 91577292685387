export default [
 
  {
    component: 'CNavItem',
    name: 'Dashboard',
    key: 'dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavTitle',
    name: 'Menu',
  },
  {
    component: 'CNavItem',
    name: 'Categories',
    key: 'view-categories',
    to: '/categories/list',
    icon: 'cil-list',
  },

  {
    component: 'CNavItem',
    name: 'Set Category Position',
    key: 'view-position',
    to: '/category-position/update',
    icon:"cil-list"
  },
  {
    component: 'CNavItem',
    name: 'Product Unit',
    key: 'view-units',
    to: '/product-unit/list',
    icon:'cil-check-circle',
  },
  {
    component: 'CNavItem',
    name: 'Product',
    key: 'view-products',
    to: '/product/list',
    icon: 'cil-basket',
  },
  {
    component: 'CNavItem',
    name: 'Orders',
    key: 'view-orders',
    to: '/orders',
    icon: 'cil-basket',
  },
  {
    component: 'CNavItem',
    name: 'Coupon',
    key: 'view-coupons',
    to: '/discount-code/list',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Notification',
    key: 'view-notification',
    to: '/notification/send',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Image Sliders',
    key: 'manage-image-sliders',
    to: '/image-slider/list',
    icon:'cil-check-circle',
  },
  {
    component: 'CNavItem',
    name: 'MinimumOrderAmount',
    key: 'update-minimum-order-amount',
    to: '/update-minimum-order-amount',
    icon: 'cil-basket',
  },
  {
    component: 'CNavItem',
    name: 'Delivery',
    key: 'view-delivery',
    to: '/delivery-fee/update',
    icon:"cil-check-circle"
  },
  
  {
    component: 'CNavItem',
    name: 'Site Setting',
    key: 'view-site-setting',
    to: '/site-setting',
    icon: 'cil-pencil',
  },
  {
    component: 'CNavItem',
    name: 'ContactUs',
    key: 'view-conactus',
    to: '/contact-us/list',
    icon:"cil-pencil"
  },
  
 
  // {
  //   component: 'CNavItem',
  //   name: 'Set Category Position',
  //   key: 'view-position',
  //   to: '/category-position/update',
  //   icon:"cil-list"
  // },
 
   {
    component: 'CNavItem',
    name: 'Offer',
    key: 'view-offers',
    to: '/offer/list',
    icon:"cil-check-circle"
  },
 
 
  {
    component: 'CNavItem',
    name: 'User',
    items: [
      {
        component: 'CNavItem',
        name: 'Employee',
        key: 'view-users',
        to: '/employee/list',
      },
      {
        component: 'CNavItem',
        name: 'Customer',
        key: 'view-users',
        to: '/user/list',
      },
    ],
    icon:'cil-user',
  },
  {
    component: 'CNavItem',
    name: 'Reviews',
    key: 'view-reviews',
    to: '/reviews',
    icon:'cil-comment-square'
  },

  
  {
    component: 'CNavItem',
    name: 'Permission',
    key: 'view-permission',
    to: '/permission/list',
    icon:"cil-shield-alt"
  },
  {
    component: 'CNavItem',
    name: 'Role',
    key: 'view-role',
    to: '/role/list',
    icon:'cil-lock-locked'
  },
  {
    component: 'CNavItem',
    name: 'Payment Methods',
    key: 'view-payment-methods',
    to: '/payment-methods/list',
    icon:"cil-dollar"
  },
 
  // {
  //   component: 'CNavItem',
  //   name: 'Blog',
  //   key: 'view-blog',
  //   to: '/blog/list',
  //   icon:"cil-dollar"
  // },
  

  /*{
    component: 'CNavTitle',
    name: 'Theme',
  },*/
]

/*
// initial route nav
export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    key: 'dashboard',
    to: '/dashboard',
  },
  {
    component: 'CNavItem',
    name: 'Catalog',
    items: [
      {
        component: 'CNavItem',
        name: 'Categories',
        to: '/categories/list',
        items: [
          {
            component: 'CNavItem',
            name: 'Create',
            key: 'create-categories',
            to: '/categories/create',
          },
          {
            component: 'CNavItem',
            name: 'List',
            key: 'view-categories',
            to: '/categories/list',
          },
        ],
      },
      /!*{
        component: 'CNavItem',
        name: 'Product Attribute',
        to: '/product-attribute/list',
        items: [
          {
            component: 'CNavItem',
            name: 'Create',
            to: '/product-attribute/create'
          },
          {
            component: 'CNavItem',
            name: 'List',
            to: '/product-attribute/list'
          },
        ]
      },*!/
      {
        component: 'CNavItem',
        name: 'Product Unit',
        to: '/product-unit/list',
        items: [
          {
            component: 'CNavItem',
            name: 'Create',
            to: '/product-unit/create',
          },
          {
            component: 'CNavItem',
            name: 'List',
            to: '/product-unit/list',
          },
        ],
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Product',
    items: [
      {
        component: 'CNavItem',
        name: 'Create',
        key: 'create-products',
        to: '/product/create',
      },
      {
        component: 'CNavItem',
        name: 'List',
        key: 'view-categories',
        to: '/product/list',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Permission',
    items: [
      {
        component: 'CNavItem',
        name: 'Create',
        to: '/permission/create',
      },
      {
        component: 'CNavItem',
        name: 'List',
        to: '/permission/list',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Role',
    items: [
      {
        component: 'CNavItem',
        name: 'Create',
        to: '/role/create',
      },
      {
        component: 'CNavItem',
        name: 'List',
        to: '/role/list',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Employee',
    items: [
      {
        component: 'CNavItem',
        name: 'Create',
        to: '/employee/create',
      },
      {
        component: 'CNavItem',
        name: 'List',
        to: '/employee/list',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'User',
    items: [
      /!*{
        component: 'CNavItem',
        name: 'Create',
        to: '/user/create',
      },*!/
      {
        component: 'CNavItem',
        name: 'List',
        to: '/user/list',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Orders',
    to: '/orders',
  },

  {
    component: 'CNavItem',
    name: 'Reviews',
    to: '/reviews',
  },

  {
    component: 'CNavItem',
    name: 'Image Sliders',
    items: [
      {
        component: 'CNavItem',
        name: 'Create',
        to: '/image-slider/create',
      },
      {
        component: 'CNavItem',
        name: 'List',
        to: '/image-slider/list',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Payment Methods',
    items: [
      {
        component: 'CNavItem',
        name: 'Create',
        to: '/payment-methods/create',
      },
      {
        component: 'CNavItem',
        name: 'List',
        to: '/payment-methods/list',
      },
    ],
  },
  /!*{
    component: 'CNavItem',
    name: 'Roles',
    to: '/roles',
  },*!/

  /!*{
    component: 'CNavTitle',
    name: 'Theme',
  },*!/
]
*/
