import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'
import DefaultLayout from '@/layouts/DefaultLayout'
import {checkMyPermission} from "@/data/services/permissionHandler";
// import {MapperUtility} from "@/data/mapper";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        meta: {
          permission: 'allowed'
        }
      },
      {
        path: '/categories',
        name: 'Category',
        redirect: '/categories/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/categories/create',
            name: 'CategoryCreate',
            component: () => import('@/views/catalog/categories/create.vue'),
            meta: {
              permission: 'create-categories'
            }
          },
          {
            path: '/categories/edit/:id',
            name: 'CategoryEdit',
            component: () => import('@/views/catalog/categories/create.vue'),
            meta: {
              permission: 'update-categories'
            }
          },
          {
            path: '/categories/list',
            name: 'CategoryList',
            component: () => import('@/views/catalog/categories/list.vue'),
            meta: {
              permission: 'view-categories'
            }
          },
        ],
      },
      /*---------Blog------------------*/
  {
    path: '/contact-us',
    name: 'ContactUs',
    redirect: '/contact-us/list',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/contact-us/create',
        name: 'ContactUsCreate',
        component: () => import('@/views/contactus/create.vue'),
        meta: {
          permission: 'create-contactus'
        }
      },
      {
        path: '/contact-us/edit/:id',
        name: 'ContactUsEdit',
        component: () => import('@/views/contactus/create.vue'),
        meta: {
          permission: 'update-contactus'
        }
      },
      {
        path: '/contact-us/list',
        name: 'ContactUsList',
        component: () => import('@/views/contactus/list.vue'),
        meta: {
          permission: 'view-contactus'
        }
      },
    ],
  },
      // {
      //   path: '/category-position/update',
      //   name: 'CategoryPosition',
      //   component: () =>
      //     import(/* webpackChunkName: "dashboard" */ '@/views/catalog/categories/position.vue'),
      //   meta: {
      //     permission: 'allowed'
      //   }
      // },
      {
        path: '/category-position/update',
        name: 'CategoryPosition',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/catalog/categories/subcatPosition.vue'),
        meta: {
          permission: 'allowed'
        }
      },
      {
        path: '/offer',
        name: 'Offer',
        redirect: '/offer/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/offer/create',
            name: 'OfferCreate',
            component: () => import('@/views/offer/create.vue'),
            // meta: {
            //   permission: 'create-offer'
            // }
          },
          {
            path: '/offer/edit/:id',
            name: 'OfferEdit',
            component: () => import('@/views/offer/create.vue'),
            meta: {
              permission: 'update-offer'
            }
          },
          {
            path: '/offer/list',
            name: 'OfferList',
            component: () => import('@/views/offer/list.vue'),
            meta: {
              permission: 'view-offer'
            }
          },
        ],
      },
      // {
      //   path: '/delevery-fee',
      //   name: 'Delevery Fee',
      //   redirect: '/delevery-fee/list',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   children: [
      //     {
      //       path: 'delevery-fee/update',
      //       name: 'deleveryFeeCreate',
      //       component: () => import('@/views/order/partials/Delivery/create.vue'),
      //       meta: {
      //         permission: 'create-delevery-fee'
      //       }
      //     },
      //     {
      //       path: '/delevery-fee/edit/:id',
      //       name: 'DeleveryEdit',
      //       component: () => import('@/views/order/partials/Delivery/create.vue'),
      //       meta: {
      //         permission: 'update-delevery-fee'
      //       }
      //     },
      //     {
      //       path: '/delevery-fee/list',
      //       name: 'DeleveryList',
      //       component: () => import('@/views/order/partials/Delivery/list.vue'),
      //       meta: {
      //         permission: 'view-delevery-fee'
      //       }
      //     },
      //   ],
      // },
      /*{
        path: '/product-attribute',
        name: 'ProductAttribute',
        redirect: '/product-attribute/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/product-attribute/create',
            name: 'ProductAttributeCreate',
            component: () =>
              import('@/views/catalog/product-attributes/create.vue'),
          },
          {
            path: '/product-attribute/edit/:id',
            name: 'ProductAttributeEdit',
            component: () =>
              import('@/views/catalog/product-attributes/create.vue'),
          },
          {
            path: '/product-attribute/list',
            name: 'ProductAttributeList',
            component: () =>
              import('@/views/catalog/product-attributes/list.vue'),
          },
        ],
      },*/
      {
        path: '/product-unit',
        name: 'ProductUnit',
        redirect: '/product-unit/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/product-unit/create',
            name: 'ProductUnitCreate',
            component: () => import('@/views/catalog/product-unit/create.vue'),
            meta: {
              permission: 'create-units'
            }
          },
          {
            path: '/product-unit/edit/:id',
            name: 'ProductUnitEdit',
            component: () => import('@/views/catalog/product-unit/create.vue'),
            meta: {
              permission: 'update-units'
            }
          },
          {
            path: '/product-unit/list',
            name: 'ProductUnitList',
            component: () => import('@/views/catalog/product-unit/list.vue'),
            meta: {
              permission: 'view-units'
            }
          },
        ],
      },
      {
        path: '/product',
        name: 'Product',
        redirect: '/product/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/product/create',
            name: 'ProductCreate',
            component: () => import('@/views/product/create.vue'),
            meta: {
              permission: 'create-products'
            }
          },
          {
            path: '/product/edit/:id',
            name: 'ProductEdit',
            component: () => import('@/views/product/create.vue'),
            meta: {
              permission: 'update-products'
            }
          },
          {
            path: '/product/list',
            name: 'ProductList',
            component: () => import('@/views/product/list.vue'),
            meta: {
              permission: 'view-products'
            }
          },
        ],
      },
      {
        path: '/discount-code',
        name: 'Coupon',
        redirect: '/discount-code/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/discount-code/create',
            name: 'CouponCreate',
            component: () => import('@/views/coupon/create.vue'),
            meta: {
              permission: 'create-coupon'
            }
          },
          {
            path: '/discount-code/edit/:id',
            name: 'CouponEdit',
            component: () => import('@/views/coupon/create.vue'),
            meta: {
              permission: 'update-coupons'
            }
          },
          {
            path: '/discount-code/list',
            name: 'CouponList',
            component: () => import('@/views/coupon/list.vue'),
            meta: {
              permission: 'view-coupons'
            }
          },
          {
            path: '/discount-code/detail/:id',
            name: 'Coupondetail',
            component: () => import('@/views/coupon/detail.vue'),
            meta: {
              permission: 'view-coupons'
            }
          },
        ],
        
      },
      {
        path: '/notification',
        name: 'Notification',
        redirect: '/notification/send',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/notification/create',
            name: 'Message-Send',
            component: () => import('@/views/notification/create.vue'),
            meta: {
              permission: 'create-coupon'
            }
          },
          
          {
            path: '/notification/send',
            name: 'Send',
            component: () => import('@/views/notification/list.vue'),
            meta: {
              permission: 'view-notification'
            }
          },
        ],
        
      },
      {
        path: '/permission',
        name: 'Permission',
        redirect: '/permission/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/permission/create',
            name: 'PermissionCreate',
            component: () => import('@/views/permission/PermissionCreate.vue'),
            meta: {
              permission: 'create-permission'
            }
          },
          {
            path: '/permission/edit/:id',
            name: 'PermissionEdit',
            component: () => import('@/views/permission/PermissionCreate.vue'),
            meta: {
              permission: 'update-permission'
            }
          },
          {
            path: '/permission/list',
            name: 'PermissionList',
            component: () => import('@/views/permission/PermissionList.vue'),
            meta: {
              permission: 'view-permission'
            }
          },
        ],
      },
      {
        path: '/payment-methods',
        name: 'PaymentMethods',
        redirect: '/payment-methods/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/payment-methods/create',
            name: 'PaymentMethodsCreate',
            component: () => import('@/views/payment-methods/PaymentModeCreate.vue'),
            meta: {
              permission: 'create-payment-methods'
            }
          },
          {
            path: '/payment-methods/edit/:id',
            name: 'PaymentMethodsEdit',
            component: () => import('@/views/payment-methods/PaymentModeCreate.vue'),
            meta: {
              permission: 'update-payment-methods'
            }
          },
          {
            path: '/payment-methods/list',
            name: 'PaymentMethodsList',
            component: () => import('@/views/payment-methods/PaymentModeList.vue'),
            meta: {
              permission: 'view-payment-methods'
            }
          },
        ],
      },
      {
        path: '/role',
        name: 'Role',
        redirect: '/role/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/role/create',
            name: 'RoleCreate',
            component: () => import('@/views/role/RoleCreate.vue'),
            meta: {
              permission: 'create-role'
            }
          },
          {
            path: '/role/edit/:id',
            name: 'RoleEdit',
            component: () => import('@/views/role/RoleCreate.vue'),
            meta: {
              permission: 'update-role'
            }
          },
          {
            path: '/role/list',
            name: 'RoleList',
            component: () => import('@/views/role/RoleList.vue'),
            meta: {
              permission: 'view-role'
            }
          },
        ],
      },
      {
        path: '/image-slider',
        name: 'ImageSlider',
        redirect: '/image-slider/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/image-slider/create',
            name: 'ImageSliderCreate',
            component: () => import('@/views/image-slider/create.vue'),
            meta: {
              permission: 'manage-image-sliders'
            }
          },
          {
            path: '/image-slider/edit/:id',
            name: 'ImageSliderEdit',
            component: () => import('@/views/image-slider/create.vue'),
            meta: {
              permission: 'manage-image-sliders'
            }
          },
          {
            path: '/image-slider/list',
            name: 'ImageSliderList',
            component: () => import('@/views/image-slider/list.vue'),
            meta: {
              permission: 'manage-image-sliders'
            }
          },
        ],
      },
      {
        path: '/employee',
        name: 'Employee',
        redirect: '/employee/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/employee/create',
            name: 'EmployeeCreate',
            component: () => import('@/views/employee/create.vue'),
            meta: {
              permission: 'create-users'
            }
          },
          {
            path: '/employee/edit/:id',
            name: 'EmployeeEdit',
            component: () => import('@/views/employee/create.vue'),
            meta: {
              permission: 'update-users'
            }
          },
          {
            path: '/employee/list',
            name: 'EmployeeList',
            component: () => import('@/views/employee/list.vue'),
            meta: {
              permission: 'view-users'
            }
          },
        ],
      },
      {
        path: '/user',
        name: 'User',
        redirect: '/user/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/user/edit/:id',
            name: 'UserEdit',
            component: () => import('@/views/user/UserCreate.vue'),
            meta: {
              permission: 'update-users'
            }
          },
          {
            path: '/user/list',
            name: 'UserList',
            component: () => import('@/views/user/UserList.vue'),
            meta: {
              permission: 'view-users'
            }
          },
        ],
      },
      {
        path: '/orders',
        name: 'Orders',
        redirect: '/orders/list',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: '/orders/list',
            name: 'OrderList',
            component: () => import('@/views/order/list.vue'),
            meta: {
              permission: 'view-orders'
            }
          },

          {
            path: '/orders/detail/:id',
            name: 'OrderDetail',
            component: () => import('@/views/order/detail.vue'),
            meta: {
              permission: 'view-orders'
            }
          },
        ]
      }
    ],
  },
  {
    path: '/reviews',
    name: 'Reviews',
    redirect: '/reviews',
    component: DefaultLayout,
    children: [
      {
        path: '/reviews',
        name: 'All Reviews',
        component: () => import('@/views/reviews/list.vue'),
        meta: {
          permission: 'view-reviews'
        }
      },
    ],
  },
  /*-----------contact us -----------*/

  

  /*---------Blog------------------*/
  {
    path: '/blog',
    name: 'Blog',
    redirect: '/blog/list',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/blog/create',
        name: 'BlogCreate',
        component: () => import('@/views/blog/create.vue'),
        meta: {
          permission: 'create-blog'
        }
      },
      {
        path: '/blog/edit/:id',
        name: 'Blogedit',
        component: () => import('@/views/blog/create.vue'),
        meta: {
          permission: 'update-blog'
        }
      },
      {
        path: '/blog/list',
        name: 'BlogList',
        component: () => import('@/views/blog/list.vue'),
        meta: {
          permission: 'view-blogs'
        }
      },
    ],
  },

  {
    path: '/site-setting',
    name: 'Site Settig',
    redirect: '/site-setting',
    component: DefaultLayout,
    children: [
      {
        path: '/site-setting',
        name: 'Update Web Info',
        component: () => import('@/views/setting/layouts.vue'),
        meta: {
          permission: 'view-site-setting'
        }
      },
    ],
  },

  {
    path: '/update-minimum-order-amount',
    name: 'MinimumOrderAmount',
    redirect: '/update-minimum-order-amount',
    component: DefaultLayout,
    children: [
      {
        path: '/update-minimum-order-amount',
        name: 'MinimumOrder',
        component: () => import('@/views/order/partials/update.vue'),
        meta: {
          permission: 'allowed'
        }
      },
    ],
  },
  {
    path: '/delivery-fee/update',
    name: 'Delivery Charge',
    redirect: '/delivery-fee/update',
    component: DefaultLayout,
    children: [
      {
        path: '/delivery-fee/update',
        name: 'Delivery Charge',
        component: () => import('@/views/order/partials/Delivery/create.vue'),
        meta: {
          permission: 'allowed'
        }
      },
    ],
  },
  
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login'),
    meta: {
      permission: 'allowed'
    }
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import('@/views/pages/Page404'),
    meta: {
      permission: 'allowed'
    }
  },
  {
    path: '/401',
    name: 'AuthorizationRequired',
    component: () => import('@/views/pages/Page401'),
    meta: {
      permission: 'allowed'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (!store.state.auth.loggedIn) {
    if (to.path === '/login') {
      next();
    } else {
      next('/login')
    }
  } else {
    // can check token validation here & add loading
    if (to.path === '/login' || to.path === '/register') {
      // if (checkMyPermission(to))
      // MapperUtility.dataLogger('before route to = ', to)
      next('/');
    } else {
      // MapperUtility.dataLogger('before route to = ', to)
      // MapperUtility.dataLogger('before route permission = ', to.meta.permission)
      if (checkMyPermission(to.meta.permission)) {
        next();
      } else {
        next('/401')
      }
    }
  }
})
export default router
