import {MapperUtility} from "@/data/mapper/index";
import {UserMapper} from "@/data/mapper/user.mapper";

export class OrderMapper {
  static mapToOrderStatuses(response) {
    MapperUtility.dataLogger('map to orders => ', response)
    const responseList = response ? response : []
    MapperUtility.dataLogger('map to orders responselist => ', responseList)
    const list = []
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const status = { value: data.key, label: data.display_name }
        list.push(status)
      })
    }
    return list
  }
  static mapToOrders(response) {
    MapperUtility.dataLogger('map to orders => ', response)
    MapperUtility.dataLogger('map to orders => ', response.data)
    const responseList = response.data ? response.data : []
    MapperUtility.dataLogger('map to orders responselist => ', responseList)
    const orders = {
      list: [], 
      first: response.links ? response.links.first : null,
      last: response.links ? response.links.last : null,
      prev: response.links ? response.links.prev : null,
      next: response.links ? response.links.next : null,
    }
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const product = this.mapToOrder(data)
        orders.list.push(product)
      })
    }
    return orders
  }
  static mapToOrder(response) {
    MapperUtility.dataLogger('mapping order resp => ', response)
    const order = {
      id: MapperUtility.checkData(response.id),
      userId: MapperUtility.checkData(response.user_id),
      customer: response.customer ? UserMapper.mapToUser(response.customer) : null,
      courierId: MapperUtility.checkData(response.courier_id),
      subtotalPrice: MapperUtility.checkData(response.subtotal_price),
      shippingCharge: MapperUtility.checkData(response.shipping_charge),
      totalPrice: MapperUtility.checkData(response.total_price),
      paymentType: MapperUtility.checkData(response.payment_type),
      paymentStatus: MapperUtility.checkData(response.payment_status),
      status: MapperUtility.checkData(response.status),
      coupon: MapperUtility.checkData(response.coupon),
      discount_amount: MapperUtility.checkData(response.discount_amount),
      trackNo: MapperUtility.checkData(response.track_no),
      orderNote: MapperUtility.checkData(response.order_note),
      cancelReason: MapperUtility.checkData(response.cancel_reason),
      createdAt: MapperUtility.checkData(response.created_at),
      deliveryAddress: response.shipping_address ? this.mapToDeliveryAddress(response.shipping_address) : null,
      orderProducts: [],
    }
    if (response.order_products) {
      if (response.order_products.length > 0) {
        response.order_products.forEach(data => {
          const orderProduct = this.mapToOrderProduct(data)
          order.orderProducts.push(orderProduct)
        })
      }
    }
    return order
  }
  static mapToOrderSummary(response) {
    MapperUtility.dataLogger('mapping order resp => ', response)
    const order = {
      total: MapperUtility.checkData(response.total),
      totalCompleted: MapperUtility.checkData(response.total_completed),
      totalPending: MapperUtility.checkData(response.total_pending),
      totalCancelled: MapperUtility.checkData(response.total_cancelled),
    }
    return order
  }
  static mapToDeliveryAddress (address) {
    const deliveryAddress = {
      id: MapperUtility.checkData(address.id),
      addressLine1: MapperUtility.checkData(address.address_line_1),
      city: MapperUtility.checkData(address.city),
      country: MapperUtility.checkData(address.country),
      email: MapperUtility.checkData(address.email),
      fullName: MapperUtility.checkData(address.full_name),
      orderId: MapperUtility.checkData(address.order_id),
      phone: MapperUtility.checkData(address.phone),
      phonePrefix: MapperUtility.checkData(address.phone_prefix),
      type: MapperUtility.checkData(address.type),
    }
    return deliveryAddress
  }
  static mapToOrderProduct(response) {
    MapperUtility.dataLogger('mapping product resp => ', response)
    const product = {
      id: MapperUtility.checkData(response.id),
      // orderId: MapperUtility.checkData(response.order_id),
      productId: MapperUtility.checkData(response.product_id),
      productName: MapperUtility.checkData(response.product_name),
      quantity: MapperUtility.checkData(response.quantity),
      unit: MapperUtility.checkData(response.unit),
      unitPrice: MapperUtility.checkData(response.unit_price),
      totalPrice: MapperUtility.checkData(response.total_price),
    }
    return product
  }
  static mapToCartItems(response) {
    MapperUtility.dataLogger('map to orders => ', response)
    MapperUtility.dataLogger('map to orders => ', response.data)
    const responseList = response.data ? response.items : []
    MapperUtility.dataLogger('map to orders responselist => ', responseList)
    const cart = {
      items: [],
      totalItems: response.total_items,
      totalPrice: response.total_price,
    }
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const item = this.mapToCartItem(data)
        cart.items.push(item)
      })
    }
    return cart
  }
  static mapToCartItem(response) {
    MapperUtility.dataLogger('mapping product resp => ', response)
    const item = {
      id: MapperUtility.checkData(response.id),
      rowId: MapperUtility.checkData(response.row_id),
      name: MapperUtility.checkData(response.name),
      price: MapperUtility.checkData(response.price),
      variationId: MapperUtility.checkData(response.variation_id),
      qty: MapperUtility.checkData(response.qty),
      options: response.options,
      totalPrice: MapperUtility.checkData(response.total_price),
    }
    return item
  }

  static mapToRecentOrders(response) {
    MapperUtility.dataLogger('order list => ', response)
    const responseList = response ? response : []
    console.log('respList',responseList);
    const recentOrders = {
     list:[],
     totalSale:responseList.total_sales
    }
    if (responseList.latest_orders.length > 0) {
      responseList.latest_orders.forEach(data => {
        // const order = this.mapToRecentOrder(data)
        recentOrders.list.push(data)
      })
    }
    return recentOrders
  }
}
