import {MapperUtility} from "@/data/mapper/index";
import {UserMapper} from "@/data/mapper/user.mapper";
import {ProductMapper} from "@/data/mapper/product.mapper";

export class CatalogMapper {
  static mapToCategoryList(response) {
    const responseList = response.data ? response.data : []
    const responseLinks = response.links ? response.links : []
    const categoryData = {
      list: [],
      link: [],
      first: response.payload ? response.payload.first_page_url : null,
      last: response.payload ? response.payload.last_page_url : null,
      prev: response.payload ? response.payload.prev_page_url : null,
      next: response.payload ? response.payload.next_page_url : null,
    }
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const category = this.mapToCategory(data)
        categoryData.list.push(category)
      })
    }
    if (responseLinks.length > 0) {
      responseLinks.forEach(data => {
        const link = MapperUtility.mapToLink(data)
        categoryData.link.push(link)
      })
    }
    return categoryData
  }
  static mapToCategories(response) {
    const responseList = response.data ? response.data : []
    const categories = []
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const category = this.mapToCategory(data)
        categories.push(category)
      })
    }
    return categories
  }
  static mapToCategory(response) {
    const category = {
      id: MapperUtility.checkData(response.id),
      name: MapperUtility.checkData(response.name),
      parentId: response.parent_id ? parseInt(response.parent_id) : '',
      hasParent: response.parent_id ? true : false,
      slug: MapperUtility.checkData(response.slug),
      description: MapperUtility.checkData(response.description),
      image: MapperUtility.checkData(response.image_url),
      active: response.active,
      position: MapperUtility.checkData(response.position),
      meta: MapperUtility.metaData(response),
      subCategories: [],
      subCategoriesId: [],
    }
    if (response.child_categories) {
      if (response.child_categories.length > 0) {
        response.child_categories.forEach(data => {
          const subCategory = this.mapToCategory(data)
          category.subCategories.push(subCategory)
          category.subCategoriesId.push(subCategory.id)
        })
      }
    }
    return category
  }
  static mapToCategoryJson(data) {
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('description', data.description)
    if (data.parentId) {
      formData.append('parent_id', data.parentId)
    }
    formData.append('slug', data.slug)
    formData.append('image', data.image)
    formData.append('active', data.active ? 1 : 0)
    // formData.append('_method', 'put')
    return formData
  }
  static mapToUpdateCategoryJson(data) {
    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('description', data.description)
    if (data.parentId) {
      formData.append('parent_id', data.parentId)
    }
    formData.append('slug', data.slug)
    formData.append('image', data.image)
    formData.append('active', data.active ? 1 : 0)
    formData.append('_method', 'put')
    return formData
  }
  static mapToProductAttributes(response) {
    const responseList = response.list ? response.list : []
    const attributes = []
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const attribute = this.mapToProductAttribute(data)
        attributes.push(attribute)
      })
    }
    return attributes
  }
  static mapToProductAttribute(response) {
    const productAttribute = {
      id: MapperUtility.checkData(response.id),
      name: MapperUtility.checkData(response.name),
    }
    return productAttribute
  }
  static mapToProductAttributeJson(data) {
    const attribute = {
      name: MapperUtility.checkData(data.name),
    }
    return attribute
  }
  static mapToUnitList(response) {
    const responseList = response.data ? response.data : []
    const responseLinks = response.links ? response.links : []
    const unitData = {
      list: [],
      link: [],
      first: response.payload ? response.payload.first_page_url : null,
      last: response.payload ? response.payload.last_page_url : null,
      prev: response.payload ? response.payload.prev_page_url : null,
      next: response.payload ? response.payload.next_page_url : null,
    }
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const unit = this.mapToProductUnit(data)
        unitData.list.push(unit)
      })
    }
    if (responseLinks.length > 0) {
      responseLinks.forEach(data => {
        const link = MapperUtility.mapToLink(data)
        unitData.link.push(link)
      })
    }
    return unitData
  }
  static mapToProductUnits(response) {
    const responseList = response.data ? response.data : []
    const units = []
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const unit = this.mapToProductUnit(data)
        units.push(unit)
      })
    }
    return units
  }
  static mapToProductUnit(response) {
    const productUnit = {
      id: MapperUtility.checkData(response.id),
      name: MapperUtility.checkData(response.name),
      description: MapperUtility.checkData(response.description),
      position: MapperUtility.checkData(response.position),
    }
    return productUnit
  }
  static mapToProductUnitJson(data) {
    const unit = {
      name: MapperUtility.checkData(data.name),
      description: MapperUtility.checkData(data.description),
      position: data.position ? data.position : null,
    }
    return unit
  }
  static mapToSubCategories(response) {
    const responseList = response.list ? response.list : []
    const subCategories = []
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const category = this.mapToSubCategory(data)
        subCategories.push(category)
      })
    }
    return subCategories
  }
  static mapToSubCategory(response) {
    const subCategory = {
      id: MapperUtility.checkData(response.id),
      name: MapperUtility.checkData(response.name),
      category: MapperUtility.checkData(response.category),
    }
    return subCategory
  }
  static mapToSubCategoryJson(data) {
    const subCategory = {
      name: MapperUtility.checkData(data.name),
    }
    return subCategory
  }
  static mapToReviews(response) {
    MapperUtility.dataLogger('start mapping reviews ', response)
    const responseList = response.data ? response.data : []
    const reviewData = {
      list: [],
      link: [],
      first: response.links ? response.links.first : null,
      last: response.links ? response.links.last : null,
      prev: response.links ? response.links.prev : null,
      next: response.links ? response.links.next : null,
    }
    if (responseList.length > 0) {
      responseList.forEach(data => {
        const review = this.mapToReview(data)
        reviewData.list.push(review)
      })
    }
    return reviewData
  }
  static mapToReview(response) {
    const productUnit = {
      id: MapperUtility.checkData(response.id),
      rating: MapperUtility.checkData(response.rating),
      comment: MapperUtility.checkData(response.comment),
      user: response.user ? UserMapper.mapToUser(response.user) : null,
      approved: response.approved,
      createdAt: MapperUtility.checkData(response.created_at),
      product: response.product ? ProductMapper.mapToProduct(response.product) : null,
    }
    return productUnit
  }
}
