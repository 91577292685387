import store from '@/store'
import {MapperUtility} from "@/data/mapper";

export function checkMyPermission (permission) {
  const roles = store.state.auth.roles
  const found = store.state.auth.permissions.find(permit => permit == permission);

  MapperUtility.dataLogger('Check permission handler start')
  MapperUtility.dataLogger('roles allowed = ', roles)
  MapperUtility.dataLogger('roles allowed = ', permission)
  if (permission === 'allowed' || roles.includes('super-admin')) {
    MapperUtility.dataLogger(`${permission}  allowed = true`)
    return true
  }if(roles !== 'super-admin' && permission === found || permission === 'allowed'){
    MapperUtility.dataLogger(`${permission} allowed = true`)
    return true
  } else {
    return false
  }
}
