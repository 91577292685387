import {CatalogService} from "@/data/services/catalog.service";
// import {MapperUtility} from "@/data/mapper";

export const catalog = {
  namespaced: true,
  state: {
    categories: [],
    subCategories: [],
    units: [],
  },
  actions: {
    getCategories({commit}) {
      // MapperUtility.dataLogger('categories dispatched');
      const catalogService = new CatalogService();
      return catalogService.getCategories().then(
        response => {
          commit('setCategoryState', response);
          // commit('setSubCategoryState', response.subCategories);
          return Promise.resolve(true);
        },
        error => {
          // MapperUtility.dataLogger('error at vuex', error)
          return Promise.reject(error);
        }
      )
    },
    getUnits({commit}) {
      // MapperUtility.dataLogger('unit dispatched');
      const catalogService = new CatalogService();
      return catalogService.getProductUnits().then(
        response => {
          commit('setUnitState', response);
          return Promise.resolve(true);
        },
        error => {
          // console.log('error at vuex')
          return Promise.reject(error);
        }
      )
    },
  },
  mutations: {
    setCategoryState(state, list) {
      // MapperUtility.dataLogger('setCategoryState mutate', list);
      state.categories = list;
    },
    setSubCategoryState(state, list) {
      // MapperUtility.dataLogger('setCategoryState mutate', list);
      state.subCategories = list;
    },
    setUnitState(state, list) {
      // MapperUtility.dataLogger('setUnitState mutate', list);
      state.units = list;
    },
  },
  getters: {
    getCategoriesState (state) {
      return state.categories
    },
    getSubCategoriesState (state) {
      return state.subCategories
    },
    getUnitsState (state) {
      return state.units
    },
  }
}
