import {authInstance as api} from "@/data/services/api";
import {AuthMapper} from "@/data/mapper/auth.mapper";
import TokenService from "@/data/services/token.service"
import axios from "axios";
import {getToken, getTokenType} from "@/data/preserved-storage";
import {MapperUtility} from "@/data/mapper";

class AuthService {
  login(user) {
    const request = AuthMapper.mapToLoginRequestJson(user);
    return api
      .post('/login', request)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          console.log('login response at api', response)
          return AuthMapper.mapToToken(response.data);
        } else {
          throw 'Error: Wrong Status';
        }
      });
  }

  async getAuthDetail (token) {
    MapperUtility.dataLogger('token =', token)
    const response = await axios({
      method: 'get',
      url: `${process.env.VUE_APP_BACKEND_AUTH_URL}/user/detail`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `${getTokenType()} ${getToken()}`
      },
    })
      .catch(e => {
        // MapperUtility.dataLogger('err auth detial api', e)
        // return AuthMapper.mapToAuthDetail(response)
        throw e
      })
    console.log('response at getauthDetailApi', response)
    return AuthMapper.mapToAuthDetail(response.data)
  }
  logout() {
    TokenService.removeLocalAccessToken();
  }

}

export default new AuthService();
