export function getToken () {
  const data = localStorage.getItem("accessKeySW");
  if (data) {
    return data;
  } else {
    return null;
  }
}

export function setToken (token) {
  localStorage.setItem("accessKeySW", token);
}

export function removeToken () {
  localStorage.removeItem("accessKeySW");
}
export function getTokenType () {
  const data = localStorage.getItem("accessKeyTypeSW");
  if (data) {
    return data;
  } else {
    return null;
  }
}

export function setTokenType (token) {
  localStorage.setItem("accessKeyTypeSW", token);
}

export function removeTokenType () {
  localStorage.removeItem("accessKeyTypeSW");
}
