<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <!-- <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>  -->
      <!-- <CDropdownItem>
        <CIcon icon="cil-envelope-open" /> Messages
        <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem> -->
      <!--      <CDropdownItem>
              <CIcon icon="cil-task" /> Tasks
              <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
              <CIcon icon="cil-comment-square" /> Comments
              <CBadge color="warning" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>-->
      <!-- <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader>
      
     <router-link to="/site-setting"> <CDropdownItem><CIcon icon="cil-settings" /> Settings </CDropdownItem></router-link>
     <router-link to="/permission/list"> <CDropdownItem>
        <CIcon icon="cil-dollar" /> Payments
        <CBadge color="secondary" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem></router-link>
      <CDropdownDivider />
      <CDropdownItem>
        <CIcon icon="cil-shield-alt" /> Lock Account
      </CDropdownItem> -->
      <CDropdownItem @click="changePassword" class="dropdownbtn">
        <CIcon icon="cil-user" /> Change Password
      </CDropdownItem>
      <CDropdownItem @click="logout" class="dropdownbtn">
        <CIcon icon="cil-lock-locked" /> Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
  <CModal
    size="l"
    :visible="showModal"
    @close="
      () => {
        showModal = false
      }
    "
  >
    <CModalHeader>
      <CModalTitle>Change Password</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <form
              class="change-password-form rounded form-box-shadow"
              @submit.prevent="onSubmit"
            >
              <div class="form-group">
                <label>Current password</label>
                <input
                  v-if="showPassword"
                  type="text"
                  class="form-control"
                  v-model="current_password"
                  placeholder="Please enter your password"
                />
                <input
                  v-else
                  type="password"
                  class="form-control"
                  v-model="current_password"
                  placeholder="Please enter your password"
                />
                <span
                  class="field-icon icon-cl"
                  @click="toggleShow"
                  v-if="current_password"
                >
                  <span class="icon is-small is-right">
                    <i
                      class="fa"
                      :class="{
                        'fa-eye-slash': showPassword,
                        'fa-eye': !showPassword,
                      }"
                    ></i>
                  </span>
                </span>
                <div class="error-msg text-danger" v-if="errors.old_password">
                     {{ errors.old_password[0] }}
                    </div>
                <div :class="{ error: v$.current_password.$errors.length }">
                  <div
                    class="input-errors"
                    v-for="error of v$.current_password.$errors"
                    :key="error.$uid"
                  >
                    <div class="error-msg text-danger">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>New password</label>
                <input
                  v-if="showPassword"
                  type="text"
                  class="form-control"
                  v-model="password"
                  placeholder="Please enter your new password"
                />
                <input
                  v-else
                  type="password"
                  class="form-control"
                  v-model="password"
                  placeholder="Please enter your new password"
                />
                <span
                  class="field-icon icon-cl"
                  @click="toggleShow"
                  v-if="password"
                >
                  <span class="icon is-small is-right">
                    <i
                      class="fa"
                      :class="{
                        'fa-eye-slash': showPassword,
                        'fa-eye': !showPassword,
                      }"
                    ></i>
                  </span>
                </span>
                <div class="error-msg text-danger" v-if="errors.password">
                     {{ errors.password[0] }}
                    </div>
                <div :class="{ error: v$.password.$errors.length }">
                  <div
                    class="input-errors"
                    v-for="error of v$.password.$errors"
                    :key="error.$uid"
                  >
                    <div class="error-msg text-danger">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea3"
                  >Confirm password</label
                >
                <input
                  v-if="showPassword"
                  type="text"
                  class="form-control"
                  v-model="confirm_password"
                  placeholder="Please confirm your password"
                />
                <input
                  v-else
                  type="password"
                  class="form-control"
                  v-model="confirm_password"
                  placeholder="Please confirm your password"
                />
                <span
                  class="field-icon icon-cl"
                  @click="toggleShow"
                  v-if="confirm_password"
                >
                  <span class="icon is-small is-right">
                    <i
                      class="fa"
                      :class="{
                        'fa-eye-slash': showPassword,
                        'fa-eye': !showPassword,
                      }"
                    ></i>
                  </span>
                </span>
                <div :class="{ error: v$.confirm_password.$errors.length }">
                  <div
                    class="input-errors"
                    v-for="error of v$.confirm_password.$errors"
                    :key="error.$uid"
                  >
                    <div class="error-msg text-danger">
                      {{ error.$message }}
                    </div>
                  </div>
                </div>
              </div>
              <loading-button
                type="submit"
                class="btn btn-primary mt-4"
                style="text-transform: inherit"
                :loading="loading"
                >{{ loading ? 'Please wait' : 'Save Changes' }}</loading-button
              >
            </form>
          </div>
        </div>
      </div>
    </CModalBody>
  </CModal>
</template>
<script>
import avatarImage from '@/assets/images/avatar.png'
import store from '@/store'
import router from '@/router'
const avatar = avatarImage
import { authInstance as api } from '@/data/services/api'
import LoadingButton from '../components/LoadingButton.vue'
import { required , minLength } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { ElNotification } from 'element-plus'

export default {
  name: 'changepassword',
  components: { LoadingButton },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      avatar: avatar,
      showModal: false,
      loading: false,
      showPassword: false,
      current_password: '',
      password: '',
      confirm_password: '',
      errors: '',
      error: '',
    }
  },
  validations() {
    return {
      current_password: { required },
      password: { required , minLength: minLength(6)},
      confirm_password: { required },
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword
    },
    logout() {
      store.dispatch('auth/logout').then(() => {
        router.push('/login')
      })
    },
    changePassword() {
      this.showModal = true
    },
    async onSubmit() {
      // check validation
      const result = await this.v$.$validate()
      if (!result) {
        // notify user form is invalid
        ElNotification({
          title: 'Field Required',
          type: 'error',
          message: `Add appropriate detail`,
          duration: 2000,
        })
        return
      }
      this.loading = true

      // perform async actions
      try {
        const response = await api.post('/change-password', {
          old_password: this.current_password,
          password: this.password,
          password_confirmation: this.confirm_password,
        })
        if (response.status === 200) {
          this.loading = false
          this.showModal = false
          ElNotification({
            title: 'Password changed',
            type: 'success',
            message: `Password changed Successfully`,
            duration: 2000,
          })
        }
      } catch (error) {
        this.loading = false
        if (error.response.status === 422) {
          console.log(error.response.data);
          this.loading = false
          this.errors = error.response.data;
        }
        if (error.response.status === 401) {
          this.loading = false
        }
      }
    },
  },
}
</script>

<style scoped>
.form-group {
    margin-bottom: 1rem;
}
.field-icon {
  float: right;
  margin-right: 10px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
.dropdownbtn {
  cursor: pointer;
}
</style>

