<template>
  <div class="alert alert-danger alert-danger text-center" v-if="warningZone"> Are you still with us? If yes then sow some movement.</div>
</template>

<script>
import store from '@/store'
import router from '@/router'

export default {
  name: 'AutoLogout',

  data() {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      warningTimmer: null,
      logoutTimmer: null,
      warningZone: false,
    }
  },

  mounted() {
    this.events.forEach(function (event) {
      window.addEventListener(event, this.resetTimmer)
    }, this);

    this.setTimers();
  },

  unmounted(){
    this.events.forEach(function (event) {
      window.removeEventListener(event, this.resetTimmer)
    }, this);

    this.resetTimmer();
  },
  
  methods: {
    setTimers() {
      this.warningTimmer = setTimeout(this.warningMessage, 14 * 60 * 1000) // 1min = 1 * 60 * 1000
      this.logoutTimmer = setTimeout(this.logoutUser, 15 * 60 * 1000)

      this.warningZone = false
    },
    warningMessage() {
      this.warningZone = true
    },
    logoutUser() {
      store.dispatch('auth/logout').then(() => {
        router.push('/login')
      })
    },
    resetTimmer() {
      clearTimeout(this.warningTimmer);
      clearTimeout(this.logoutTimmer);
      
      this.setTimers();
    },
  },
}
</script>

<style>
</style>