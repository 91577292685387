<template>
  <div>
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <auto-logout />
      <auto-call-api/>
      <div class="body flex-grow-1 p-3">
        <router-view />
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
// import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import AutoLogout from '@/components/AutoLogout.vue'
import AutoCallApi from '@/components/AutoCallOrderApi.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    AutoLogout,
    AutoCallApi
    // CContainer,
  },
  created() {
    // window.addEventListener(
    //   'beforeunload',
    //   (event) => {
    //     console.log(performance.navigation.type, 'performance.navigation.type')

    //     this.$store.dispatch('auth/logout')

    //     event.preventDefault()

    //     event.returnValue = ''
    //   },
    //   false,
    // )
  },
  mounted() {
    this.getInitialData()
  },
  methods: {
    async getInitialData() {
      await this.$store.dispatch('catalog/getCategories').then(() => {})
      await this.$store.dispatch('order/getOrders').then(() => {})
      await this.$store.dispatch('catalog/getUnits').then(() => {})
    },
  },
}
</script>
