<template>
    <button :disabled="loading">
        <span class="d-inline-flex align-items-center">
           <div v-if="loading" class="spinner mr-2" />
           <slot />
        </span>
     </button>
   </template>
   
   <script>
   export default {
       props: {
           loading: Boolean   
       }
   }
   </script>
   