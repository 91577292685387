import { createStore } from 'vuex'
import {auth} from "@/store/module/auth.module";
import VuexPersistence from "vuex-persist";
import {catalog} from "@/store/module/catalog.module";
import {order} from "@/store/module/order.module";


const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {
    auth,
    catalog,
    order
  },
  plugins: [
    vuexLocal.plugin
  ]
})
