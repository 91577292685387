class TokenService {
  getLocalRefreshToken() {
    const data = JSON.parse(localStorage.getItem("accessToken"));
    return data?.refreshToken;
  }
  getLocalAccessToken() {
    const data = JSON.parse(localStorage.getItem("accessToken"));
    return data?.accessToken;
  }
  setLocalAccessToken(token) {
    localStorage.setItem("accessToken", JSON.stringify(token));
  }
  updateLocalAccessToken(token) {
    let data = JSON.parse(localStorage.getItem("accessToken"));
    data.accessToken = token;
    localStorage.setItem("accessToken", JSON.stringify(data));
  }
  removeLocalAccessToken() {
    localStorage.removeItem('accessToken');
  }
  // getUser() {
  //   return JSON.parse(localStorage.getItem("user"));
  // }
  // setUser(user) {
  //   console.log(JSON.stringify(user));
  //   localStorage.setItem("user", JSON.stringify(user));
  // }
  // removeUser() {
  //   localStorage.removeItem("user");
  // }
}
export default new TokenService();
