<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <div class="w-100 d-flex justify-content-center">
        <img :src="sidebarLogo" style="width: auto; height: 60px" alt="" />
      </div>
      <!--      <CIcon
              custom-class-name="sidebar-brand-full"
              :icon="logoNegative"
              :height="35"
            />
            <CIcon
              custom-class-name="sidebar-brand-narrow"
              :icon="sygnet"
              :height="35"
            />-->
    </CSidebarBrand>
    <!--    <div @click="getUserPermission">
      Refresh
    </div>-->
    <AppSidebarNav />
    <!--    <CSidebarToggler
          class="d-none d-lg-flex"
          @click="$store.commit('toggleUnfoldable')"
        />-->
    <CSidebarfooter style="background: #eee !important; padding-bottom: 15px">
      <div class="w-100 justify-content-center"></div>
    </CSidebarfooter>
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
import logo from '@/assets/images/logo.png'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      sidebarLogo: logo,
    }
  },
  computed: {
    tokenState() {
      return this.$store.getters['auth/getTokenState']
    },
  },
  methods: {
    getUserPermission() {
      const token = {
        tokenType: this.tokenState.tokenType,
        accessToken: this.tokenState.accessToken,
      }
      this.$store.dispatch('auth/getAuthDetail', token).then(
        () => {
          this.$router.push('/')
        },
        () => {
          this.isLoading = false
        },
      )
    },
  },
}
</script>
<style lang="scss">
.sidebar-nav {
  .nav-icon {
    color: #333 !important;
  }
  .nav-group-items .nav-link {
    padding-left: 2.5rem !important;
  }
  .sidebar {
    background: #333 !important;
  }
}
</style>
<style>
.sidebar-brand {
  background: #eee !important;
  padding-bottom: 15px;
}
.sidebar-nav {
  background: #fff !important;
}
.sidebar-nav .nav-title {
  color: #333 !important;
}
.sidebar-nav .nav-link {
  color: #333 !important;
}
.sidebar-nav .nav-link.active {
  background: rgba(255, 110, 64, 0.15);
  color: #ff6e40;
  border-left-color: #ff6e40;
  border-right-color: #ff6e40;
}
.sidebar-nav .nav-group.show {
  background: #eee !important;
}
 .sidebar-nav .nav-group-toggle::after {
    background-color: #333 !important;
    border-radius: 50%;
 }
</style>
